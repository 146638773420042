:root {
    /* Branding (variants precalculated like in JS) */
    --fontFamily: "Rethink Sans", sans-serif;
    --primaryColor: #6349ce;
    --primaryColorDark: #4733b5;
    --primaryColorLight: #7e5fe8;
    --primaryColorTransparent: rgba(99, 73, 206, 0.15);
    --secondaryColor: #3e327e;
    --secondaryColorDark: #261f67;
    --secondaryColorLight: #564796;
    --backgroundColor: #d1d1f4;
    --logo: url(/logo.svg);
    --logoWidth: 1728;
    --logoHeight: 484;

    /* Color palette */
    --bubblegum: #c74e81;
    --container: #f3f3f3;
    --gray1: #333;
    --gray2: #666;
    --gray3: #888;
    --gray4: #aaa;
    --gray5: #bbb;
    --gray6: #ddd;
    --gray7: #eee;
    --purple: #976ca0;
    --seablue: #1b90ba;
    --signalBlue: #3457b5;
    --signalBlueActive: #06429c;
    --signalGray: #c8c8c8;
    --signalGrayActive: #afafaf;
    --signalGreen: #16934f;
    --signalGreenActive: #007b3a;
    --signalOrange: #e57b2d;
    --signalOrangeActive: #c96413;
    --signalRed: #be1e24;
    --signalRedActive: #a20011;
    --surface: #fafafa;
    --turquoise: #359390;
    --yellow: #dea943;

    /* Layout */
    --spacing: 8px;
    --zIndexOverlay: 10;
    --zIndexSticky: 20;
    --zIndexBackdrop: 30;

    /* Breakpoints */
    --sm: 480px;
    --md: 768px;
    --lg: 1024px;
    --xl: 1280px;
    --ul: 1440px;

    /* Animation */
    --fastOutSlowIn: cubic-bezier(0.4, 0, 0.2, 1);
    --easeInOut: cubic-bezier(0.4, 0, 0.6, 1);
}
