.container {
    display: grid;
    align-items: flex-start;
    gap: calc(3 * var(--spacing));
    grid-template-columns: minmax(0, 1fr);

    & > * {
        width: 100%;
        min-width: 0;
    }

    @media (--mdUp) {
        grid-template-columns: 1fr 1fr;
    }

    @media (--lgUp) {
        grid-template-columns: 1fr 1fr 1fr;
    }

    @media (--xlUp) {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
}
