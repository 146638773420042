.flatpickr-calendar {
    &.arrowTop::after {
        border-bottom-color: var(--container);
    }
}

.flatpickr-months {
    display: flex;
    align-items: center;
    padding: var(--spacing) calc(0.5 * var(--spacing));
    background: var(--container);

    & .flatpickr-month {
        display: flex;
        height: auto;
        justify-content: center;

        & .flatpickr-current-month {
            position: static;
            display: flex;
            width: auto;
            height: auto;
            align-items: center;

            & .cur-month:hover {
                background: transparent;
            }
        }
    }

    & .flatpickr-prev-month,
    & .flatpickr-next-month {
        position: static;

        & svg {
            fill: var(--primaryColor);
            stroke: var(--primaryColor);
        }

        &:hover svg,
        &:focus svg {
            fill: var(--primaryColorDark);
            stroke: var(--primaryColorDark);
        }
    }
}

.flatpickr-weekdays {
    height: auto;
    padding: calc(2 * var(--spacing)) 0;
    background: var(--container);
}

.flatpickr-day {
    &.highlighted {
        background: #e6e6e6;
    }

    &.selected,
    &.startRange,
    &.endRange,
    &.selected.inRange,
    &.startRange.inRange,
    &.endRange.inRange,
    &.selected:focus,
    &.startRange:focus,
    &.endRange:focus,
    &.selected:hover,
    &.startRange:hover,
    &.endRange:hover,
    &.selected.prevMonthDay,
    &.startRange.prevMonthDay,
    &.endRange.prevMonthDay,
    &.selected.nextMonthDay,
    &.startRange.nextMonthDay,
    &.endRange.nextMonthDay {
        border-color: var(--primaryColor);
        background: var(--primaryColor);
    }
}
